// Filename - pages/index.js

import React from "react";
import Navbar from "../components/Navbar";

const Home = () => {
	return (
        <Navbar />
	);
};

export default Home;
